@font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Cadiz Regular'), url('./assets/fonts/Cadiz.otf') format('opentype');
 }

 @font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Cadiz'), url('./assets/fonts/Cadiz.otf') format('opentype');
 }

 @font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Cadiz Light'), url('./assets/fonts/Cadiz-Light.otf') format('opentype');
 }

 @font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Cadiz SemiBold'), url('./assets/fonts/Cadiz-SemiBd.otf') format('opentype');
 }

 @font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Cadiz Bold'), url('./assets/fonts/Cadiz-Bold.otf') format('opentype');
 }

 @font-face {
  font-family: 'Cadiz';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Cadiz Black'), url('./assets/fonts/Cadiz-Black.otf') format('opentype');
 }

 @font-face {
  font-family: 'Blenny';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Blenny'), url('./assets/fonts/Blenny_Trial_Blk.ttf') format('truetype');
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

